<template>
  <div class="user" :class="{ ntp : user.next_to_pick, me: user.is_me }" @click="$emit('click')">
    <div v-if="!selected_player">
      <div class="back" @click="$emit('back')"> Back </div>
      <div class="img" :style="{ 'background-image': 'url(' + user.img + ')' }"></div>
      <div class="meta">
        <div class="name"><b>{{user.name}}</b><span>{{left_to_draft}} players to draft</span></div>
        <div class="avail"><span>Max Bid</span><br>${{user.avail}}</div>
      </div>
    
      <div class="pad-main">
        <Player v-for="(player, index) in user.players" :key="index" :player="player" @click="selectPlayer(player)" />
      </div>
    </div>
    <div v-else class="pad-main">
      <h1 style="font-size:18px; margin-bottom: 10px;">Enter your winning bid <br/>for the {{selected_player.posName}} slot</h1>
      <h2 style="margin-top:0; font-size: 24px;">Your max bid is <i>${{user.avail}}</i></h2>
      <div class="input-group">
          <input v-model="draft_name" type="text" @keyup="search" placeholder="Player name" />
          <input v-model="draft_price" type="number" placeholder="Draft price" />
      </div>
      <div>
        <span v-for="plyr in autocomplete" :key="plyr.id" class="autocomplete" :style="{ 'background-image' : 'url(/nfl/' +plyr.nflTeam+ '.png)' }" @click="selectAutocomplete(plyr)" :class="{ disable: !plyr.available }">{{plyr.name}} ({{plyr.position}})</span>
      </div>
      <div class="buttons">
        <button @click="selected_player = null" class="outline btn">Cancel</button>
        <button @click="lockIn" v-if="draft_name != '' && draft_price != ''" class="btn">Lock In</button>
      </div>
    </div>

  </div> 
</template>

<script>
import axios from 'axios';
import Player from './Player.vue';

function debounce(func, wait) {
  let timeout
  return function(...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export default {
  name: 'UserView',
  components: {
    Player
  },
  data() {
    return {
      selected_player: null,
      draft_name: '',
      draft_price: '',
      autocomplete: [],
      is_edit: false
    }
  },
  props: {
    user: Object
  },
  computed: {
    total_to_draft: function(){
      return this.user.players.length;
    },
    left_to_draft: function() {
      let drafted = 0;

      for(let i = 0; i < this.user.players.length; i++){
        if(this.user.players[i]['draft']) drafted++;
      }

      return this.user.players.length - drafted;
    }
  },
  methods: {
    selectAutocomplete(plyr) {
      if(!plyr.available){
        this.$confirm({
          message: 'That player was already drafted by ' + plyr.draftingTeamName,
          button: { yes: 'OK' }
        });
      } else {
        this.draft_name = plyr.name;
        this.autocomplete = [];
        this.search();
      }
    },
    search: debounce(function() {
      if(this.draft_name == '') this.autocomplete = [];
      const posAbbr = this.selected_player.pos.replace(/[0-9]/g, '');
      let positionFilter = '&positionFilter=' + posAbbr;
      if(posAbbr == 'BN') positionFilter = '';
      axios.get('/api/players/search?searchTerm='+ this.draft_name + positionFilter).then(data => {
        let autocomplete = [];
        let empty = false;

        data.data.response.forEach(player => {
          autocomplete.push(player);
          if(this.draft_name == player.name) empty = true;
        });

        if(!empty) this.autocomplete = autocomplete;
      });
    }, 200),
    selectPlayer(player){
      this.is_edit = false;
      this.draft_name = '';
      this.draft_price = '';
      if(player == null) { 
        this.selected_player = null;
        return;
      }

      if(!SERVER.logged_in.is_admin && !this.user.is_me) return;
      if(player.draft && !SERVER.logged_in.is_admin) return;

      if(player && player.draft ){
        this.is_edit = true;
        this.draft_name = player.draft.name;
        this.draft_price = parseInt(player.draft.price);
      }

      this.selected_player = player;
    },
    lockIn(){
      axios.post('api/rosteredplayers/commit', { player: this.draft_name, spend: this.draft_price, draftedPosition: this.selected_player.pos, is_edit: this.is_edit, team_id: this.user.id }).then(() => {
        this.selected_player.draft = {
          name: this.draft_name,
          price: this.draft_price,
          draftedPosition: this.selected_player.pos
        };
      }).catch((error) => {
        this.$confirm({
          message: 'Ah Shit: ' + error.response.data.response.message,
          button: { yes: 'OK' }
        });
      }).finally(() => {
        this.selectPlayer(null);
      });
    }
  }
}
</script>

<style scoped>
  .name b {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
  }

  .autocomplete {
    background-color: #4d40a3;
    display: block;
    margin-top: 10px;
    padding: 15px 24px;
    border-radius: 9px;
    color: #fff;
    cursor: pointer;
    background-position: -37px;
    background-size: 100px;
    background-repeat: no-repeat;
    border: 1px solid #9059ff;
    padding-left: 75px;
    text-align: left;
  }

  .autocomplete.disable {
    opacity: .5;
  }

  h1 {
    max-width: 400px;
    margin: 25px auto 25px auto;
    
  }

  .buttons {
    display: flex;
    margin-top: 20px;
  }

  .buttons button:first-child {
    margin-right: 15px;
  }

  .back {
    position: absolute;
    top: 10px;
    left: 15px;
    background: #201d45;
    padding: 10px 22px;
    cursor: pointer;
    z-index: 1;
    background: linear-gradient(329deg,#1d8abc,#00c058);
    text-transform: uppercase;
    font-weight: 700;
    height: 25px;
    line-height: 27px;
    border-radius: 41px;
  }

  .img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
  }

  .meta {
    display: flex;
    margin: 15px;
  }

  .name, .avail {
    font-size: 30px;
    font-weight: bold;
    text-align: right;
    line-height: 26px;
  }

  .avail {
    width: 85px;
    flex-shrink: 0;
  }

  .name {
    flex-grow: 1;
    text-align: left;
    line-height: 26px;
    margin-top: 6px;
    min-width: 0;
  }


  span {
    font-size: 18px;
    color: #6F7692;
    text-transform: uppercase;
  }

  .user {
    position: relative;
  }

</style>
