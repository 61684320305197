<template>
  <div class="grid">
    <div class="col positions" v-if="users.length > 0">
        <div class="header"> </div>
        <Player v-for="(player, index) in users[0].players" :key="index" :player="player" :teamOnly="true" />
    </div>
    <div class="col" v-for="user in users" :key="user.id">
        <div class="header"><span>{{user.name}}</span> <b class="avail">${{user.avail}}</b></div>
        <Player class="playercol" v-for="(player, index) in user.players" :key="index" :player="player" />
    </div>
  </div>
</template>

<script>
import Player from './PlayerCompact.vue';

export default {
  name: 'TvView',
  components: {
    Player
  },
  props: {
    users: Array
  },
  methods: {
  }
}
</script>

<style scoped>
.header{
  flex-grow: 1;
  flex-basis: 0;
  padding: 5px;
  text-align: center;
}
.header span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-weight: bold;
}
.grid {
    grid-template-columns: minmax(0, 60px) repeat(12, minmax(0, 1fr));
    display: grid;
    height: 100%;
    padding-right:15px;
}

.col {
  height: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  padding-top:15px;
  padding-bottom: 15px;
  box-sizing: border-box;
}

.avail {
  color: #4bf6a9;
  font-size: 25px;
}


@media only screen and (max-width: 900px) {
  .grid {
    display: flex;
    flex-direction: column;
    width: 100%; 
    max-width: 400px;
  }

  .positions {
    display: none;
  }

  .playercol {
    flex-basis: auto;
    height: 40px;
  }
}
</style>
