<template>
  <div v-show="show" class="notification fade-in" @click="$emit('click')">
    <div class="notification-inner">
      <div class="team" :style="{ 'background-image' : 'url(/nfl/' +notification.nflTeam+ '.png)' }"></div>
      <div class="title" v-html="notification.title"></div>
      <div class="subtitle">{{notification.subtitle}}</div>
    </div>
    <div class="foot">TAP TO DISMISS</div>
  </div>  
</template>

<script>
export default {
  name: 'AppNotification',
  props: {
    notification: Object,
    show: Boolean
  }
}
</script>

<style scoped>
  .foot {
    position: fixed;
    left:0;
    right: 0;
    bottom: 20px;
    text-align: center;  
    font-size: 20px;
    color: #57c2ef;
  }

  .notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background: linear-gradient(329deg, #1d8abc 0%, #00c058 100%);

    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 30px;
    font-weight: bold;
    text-align: center;
    z-index: 200;
  }

  .notification-inner {
    max-width: 400px;
    margin: 0 auto 25px auto;
    padding: 0 25px;
  }

  .subtitle {
    margin-top: 45px;
    font-size: 40px;
  }

  .team {
    position: fixed;
    top: 0;
    left: 50%;
    width: 190px;
    height: 191px;
    z-index: 201;
    background-size: contain;
    transform: translateX(-50%);
    background-repeat: no-repeat;
  }
</style>
