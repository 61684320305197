<template>
  <div class="user" :class="{ ntp : user.next_to_pick, me: user.is_me, bar: bar }" @click="$emit('click')">
    <div class="ntp">Next <br>to pick</div>
    <div class="img" :style="{ 'background-image': 'url(' + user.img + ')' }"></div>
    <div class="name">{{user.name}}<br><span>drafted {{left_to_draft}} of {{total_to_draft}} players</span></div>
    <div class="avail">${{user.avail}}</div>
  </div>  
</template>

<script>
export default {
  name: 'UserButton',
  props: {
    user: Object,
    bar: Boolean
  },
  computed: {
    total_to_draft: function(){
      return this.user.players.length;
    },
    left_to_draft: function() {
      let drafted = 0;

      for(let i = 0; i < this.user.players.length; i++){
        if(this.user.players[i]['draft']) drafted++;
      }

      return drafted;
    }
  }
}
</script>

<style scoped>
  .user {
    cursor: pointer;
    background: rgba(16, 22, 50, 0.78);
    border: 1px solid #434DAE;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
  }

  .user.bar {
    background: rgba(16, 22, 50, 1);
    border: 5px solid #00000029 !important;
    border-radius: 0;
    margin-bottom: 0;
  }

  span {
    font-weight: normal;
    color: #6F7692;
    font-size: 16px;
  }

  .user .ntp {
    display: none;
    position: absolute;
    background-color:#43AE7B;
    top:-1px;
    left:-1px;
    bottom:-1px;
    width: 75px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .user.ntp .ntp{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user.ntp {
    border: 3px solid #43AE7B;
  }

  .user.me .name{
    color: #ff8668;
  }

  .user.me.ntp {
    border-color: #ff8668;
  }

  .user.me .ntp {
    background-color: #ff8668;
  }

  .img {
    flex-shrink: 0;
    width:65px;
    height: 65px;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
    margin-right: 15px;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
    padding-right: 15px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avail {
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
  }


</style>
