<template>
  <div class="outer">
    <div class="inner">
      <div class="login">
        <img src="~@/assets/logo.png" width="200px" />
        <div class="input-group">
          <input v-model="username" type="text" placeholder="Your email" />
          <input v-model="password" type="password" placeholder="Your password" @keypress.enter="login" />
        </div>
        <button @click="login" class="blur btn">Sign In</button>
        <div style="font-size:16px;">Interested in your own custom <br>live auction draft app?<br>Email contact@fantasyidiots.com</div>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginPage',
  data(){
    return {
      username: '',
      password: ''
    }
  },
  methods:{
    login(){
      axios.post('/login', { email: this.username, password: this.password, kmli : 1 }).then(() => {
        window.location.reload();
      }).catch((error) => {
        this.$confirm({
          message: 'Ah Shit: ' + error.response.data.response,
          button: { yes: 'OK' }
        });
      });
    }
  }
}
</script>

<style scoped>
  .input-group {
    margin-bottom: 30px;
  }
  button {
    margin-bottom: 20px;
  }
  .login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-right:60px;
    padding-left: 60px;
  }
  .inner {
    background-image: url('~@/assets/mvp.png');
    background-repeat: no-repeat;
    background-size: 570px;
    background-position: center center;
    max-height: 670px;
  }
</style>
