<template>
  <div class="player" :class="{'nobg' : teamOnly, 'empty' : !player.draft}" :style="{ 'background-image' : 'url(/nflbg/' +nflTeam+ '.png)' }">
    <div v-if="teamOnly" class="pos">
      <span>{{player.pos}}</span>
    </div>
    <div v-else class="pos">
      <template  v-if="player.draft"><span>{{player.draft.name}}</span> ${{parseInt(player.draft.price)}}</template>
      <template v-else><span> </span></template>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'PlayerButtonCompact',
  props: {
    player: Object,
    teamOnly: Boolean
  },
  computed: {
    nflTeam(){
      if(this.player.draft && this.player.draft.nflTeam) return this.player.draft.nflTeam;
      return 'UNK';
    }
  }
}
</script>

<style scoped>
  .player {
    cursor: pointer;
    background: rgba(16, 22, 50, 1);
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 1px;
    border-radius: 12px;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    background-size: cover;
    border: none;
    text-align: center;
    background-position: center;
    flex-grow: 1;
    flex-basis: 0;
    text-shadow: 1px 1px 4px black;
    
  }

  .player.empty {
    background: rgba(16, 22, 50, .4);
    border-color: transparent;
  }

  .player.nobg {
    text-align: right;
    text-transform: uppercase;
    background: none !important;
    border-color: transparent;
    font-size: 24px;
  }

  .pos {
    font-size: 14px;
    font-weight: bold;
    color: #4bf6a9;
    text-transform: uppercase;
    flex-grow: 1;
    background: none !important;
  }

  .player.nobg .pos{
    font-size: 16px;
  }

  span {
    color: white;
    text-transform: none;
  }

  .draft {
    font-size: 12px;
    font-weight: bold;
    background-repeat: no-repeat;
    background-position: center right;
    line-height: 60px;
  }
</style>
