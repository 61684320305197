<template>
  <div class="draft outer">
    <div class="icons" v-if="!selected_user">
      <div class="mute" v-if="!tv_mode" :class="{ off : mute }" @click="toggleMuteConfirm"></div>
      <div class="tv" :class="{ off : tv_mode }" @click="toggleTvConfirm"></div>
    </div>
    <template v-if="!tv_mode">
      <AppNotification :notification="notification" :show="showNotification" @click="hideNotification" />
      <div v-if="loaded" class="inner">
        <Transition name="fade">
          <User v-if="selected_user" :user="next_to_pick" :bar="true" class="botass" />
        </Transition>
        <Transition :name="transition">
          <UserView style="position:absolute; left: 0; right: 0;" v-if="selected_user" :user="selected_user" @back="selectUser(null)" />
          <DraftView style="position:absolute; left: 0; right: 0;" v-if="!selected_user" :users="users" @click="selectUser" />
        </Transition>
      </div>
      <div v-else>LOADING...</div>
    </template>
    <template v-else>
      <TvView :users="users" />
    </template>
  </div>  
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import AppNotification from './Notification.vue';
import DraftView from './DraftView.vue';
import UserView from './UserView.vue';
import User from './User.vue';
import TvView from './TvView.vue';
import { Howl } from 'howler';
import { setCookie, getCookie } from '../util.js';

let notificationInterval = null;

export default {
  name: 'MainPage',
  components:{
    DraftView,
    UserView,
    AppNotification,
    User,
    TvView
  },
  props: {
    msg: String
  },
  data(){
    return {
      notification: {
        title: '',
        subtitle: '',
        nflTeam: 'UNK'
      },
      transition: 'slide-fade',
      next_to_pick: {},
      showNotification: false,
      loaded: false,
      selected_user: null,
      users: [],
      events: [],
      draft_state: {},
      player_autocomplete: [],
      user: null,
      tv_mode: false,
      mute: false,
      hide_comments: false
    }
  },
  mounted(){
    this.fetch();
    this.tv_mode = getCookie("tvmode") == 'on' ? true : false;
    this.mute = getCookie("mute") == 'on' ? true : false;
  },
  methods : {
    toggleTvConfirm(){
      if(this.tv_mode) return this.toggleTvMode();
      this.$confirm(
        {
          message: 'Are you sure you want tv mode? This is going to be bad.',
          button: { no: 'No', yes: 'Fuck it' },
          callback: confirm => {
            if (confirm) {
              this.toggleTvMode();
            }
          }
      });
    },
    toggleTvMode(){
      this.tv_mode = !this.tv_mode;
      console.log('tv mode', this.tv_mode);
      const tvCookie = this.tv_mode ? 'on' : 'off';
      setCookie('tvmode', tvCookie);
    },
    toggleMuteConfirm(){
      if(this.mute) return this.toggleMute();
      this.$confirm(
        {
          message: 'Are you sure you want to turn off sound? These sounds are lit.',
          button: { no: 'No', yes: 'I guess' },
          callback: confirm => {
            if (confirm) {
              this.toggleMute();
            }
          }
      });
    },
    toggleMute(){
      this.mute = !this.mute;
      const muteCookie = this.mute ? 'on' : 'off';
      setCookie('mute', muteCookie);
    },
    hideNotification(){
      if(notificationInterval) clearTimeout(notificationInterval);
      this.showNotification = false;
    },
    handleNotifications(events){
      if(this.tv_mode) return;
      
      let lastEventID = getCookie("lastEventID");
      if(!lastEventID) lastEventID = 0;

      if(!events || events.length == 0 || !events[0]) return;

      const event = events[0];
      if(+event.id <= +lastEventID) return;

      //Play sound
      if(!this.mute){
        var sound = new Howl({
          src: ['/sounds/' + event.data.sound + '.mp3']
        });

        sound.play();
      }

      this.notification.title = event.event;
      this.notification.subtitle = event.data.remark;

      if(this.hide_comments) this.notification.subtitle = '';

      this.notification.nflTeam = event.data.nflTeam;
      this.showNotification = true;

      if(notificationInterval) clearTimeout(notificationInterval);
      notificationInterval = setTimeout(() => {
        this.showNotification = false;
      }, 6000);

      lastEventID = event.id;
      setCookie("lastEventID", lastEventID);
    },
    fetch(){
      axios.get('/api/draft/poll').then((data) => {
        this.draft_state = data.data.response.draftState;

        let users = [];

        data.data.response.teams.forEach(team => {
          let user = {
            id: team.id,
            name: team.name,
            avail: +team.maxBid,
            img: team.imageURI,
            is_me: team.id == SERVER.logged_in.teamID,
            is_admin: team.id == SERVER.logged_in.teamID && SERVER.logged_in.is_admin,
            next_to_pick: team.draftingNow,
            players: team.players
          };

          if(user.next_to_pick) this.next_to_pick = user;

          users.push(user);

          this.users = users;
          this.loaded = true;

          if(this.selected_user && user.id == this.selected_user.id) this.selected_user = user;
        });

        this.handleNotifications(data.data.response.events);
      }).catch(() => {
        //toast maybe
      }).finally(() => {
        setTimeout(this.fetch, 2000);
      });
    },
    selectUser(user){
      if(!user) this.transition = 'slide-fade-back';
      else this.transition = 'slide-fade';
      console.log(this.transition);
      this.selected_user = user;

      Vue.nextTick(function () {
        document.getElementsByClassName('outer')[0].scrollTo(0,0);
      });
    }
  }
}
</script>

<style scoped>
.botass{
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index:10;
}

.mute {
  width: 24px;
  height: 24px;
  background: url('~@/assets/volume-mute.svg');
  opacity: .5;
}

.tv {
  width: 24px;
  height: 24px;
  background: url('~@/assets/vintage-tv.svg');
  margin-top: 10px;
  opacity: .5;
}

.tv.off, .mute.off {
  opacity: 1;
}

.icons {
  position: absolute;
  top:15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  z-index: 5;
}
</style>
