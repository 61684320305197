<template>
  <div>
      <img src="~@/assets/logo.png" width="150px" />
      <div class="pad-main">
        <User v-for="user in users" :key="user.id" :user="user" @click.native="selectUser(user)" />
      </div>
  </div>
</template>

<script>
import User from './User.vue';

export default {
  name: 'DraftView',
  components: {
    User
  },
  props: {
    users: Array
  },
  methods: {
    selectUser(user){
      this.$emit('click', user);
    }
  }
}
</script>

<style scoped>
</style>
