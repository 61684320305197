<template>
  <div class="player" :class="{locked : player.draft ? true: false}" @click="$emit('click')" :style="{ 'background-image' : 'url(/nfl/' +nflTeam+ '.png)' }">
    <div class="pos">
      {{player.posName}}<br>
      <span v-if="player.draft">{{player.draft.name}}</span>
    </div>
    <div v-if="player.draft" class="draft">
      ${{player.draft.price}}
    </div>
  </div>  
</template>

<script>
export default {
  name: 'PlayerButton',
  props: {
    player: Object
  },
  computed: {
    nflTeam(){
      if(this.player.draft && this.player.draft.nflTeam) return this.player.draft.nflTeam;
      return 'UNK';
    }
  }
}
</script>

<style scoped>
  .player {
    cursor: pointer;
    background: rgba(16, 22, 50, 0.78);
    border: 1px solid #434DAE;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    height: calc(100% * 0..0625);
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    background-position: -20px;
    background-size: 79px;
    background-repeat: no-repeat;
    height: 55px;
  }

  .player.locked {
    border: 1px solid #181e5c;
  }

  .pos {
    font-size: 18px;
    margin-left: 50px;
    font-weight: bold;
    color: #6F7692;
    text-transform: uppercase;
    flex-grow: 1;
  }

  span {
    color: white;
    text-transform: none;
  }

  .draft {
    font-size: 20px;
    font-weight: bold;
    background-repeat: no-repeat;
    background-position: center right;
    line-height: 60px;
  }
</style>
