<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <LoginPage v-if="!logged_in" />
    <MainPage v-else />
  </div>
</template>

<script>
import LoginPage from './components/LoginPage.vue';
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    LoginPage,
    MainPage
  },
  data() {
    return {
      logged_in: false
    }
  },
  mounted(){
    this.logged_in = SERVER.logged_in; // eslint-disable-line no-undef
  }
}
</script>

<style>
body, html {
  width:100%;
  height: 100%;
  margin:0;
  padding:0;
}

body {
  background: radial-gradient(circle at 45.83% 10.38%, #0C081C, transparent 76%),radial-gradient(circle at 65.17% 18.5%, #0C081C, transparent 46%),radial-gradient(circle at 96.69% 97.92%, #7833A7, transparent 61%),radial-gradient(circle at 8.13% 78.13%, #13399E, transparent 53%),radial-gradient(circle at 50% 50%, #171212, #171212 100%);
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  width: 100%;
  height: 100%;
}

.outer {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  flex-grow: 1;
  max-width: 650px;
  height: 100%;
  text-align: center;
  position: relative;
}

.input-group{
  background: rgba(16, 22, 50, 0.78);
  border: 1px solid #434DAE;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

input[type=text], input[type=password], input[type=tel], input[type=number] {
  background: transparent;
  border:none;
  font-size: 18px;
  padding: 20px;
  outline: none;
  color: white;
}

.input-group input {
  border-bottom: 1px solid #434DAE;
}

.input-group input:last-child {
  border:none;
} 

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6F7692;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #6F7692;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #6F7692;
}


button.btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border:none; 

  background: linear-gradient(90deg, rgba(44, 97, 245, 0.64) 0%, rgba(137, 81, 246, 0.64) 100%);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  display: block;
  width:100%;
  padding: 20px 45px;
  color:white;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
}

button.blur {
    background: rgba(137, 81, 246, 0.34);
    backdrop-filter: blur(10px);
}

button.outline {
  background: none;
  border: 1px solid rgba(137, 81, 246, .34)
}

h1{
  margin-top: 0;
}

h2 {
  font-size: 28px;
  color: #6f7692;
  text-transform: uppercase;
}

.pad-main {
  padding-left: 15px; 
  padding-right: 15px;
  padding-bottom: 110px;
}

i{
    font-style: normal;
    color: #fcc094;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(0.470, 0.000, 0.745, 0.715);
	animation-duration: .55s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .25s ease;
}
.slide-fade-leave-active {
  transition: all .25s cubic-bezier(0.470, 0.000, 0.745, 0.715);
}
.slide-fade-enter
{
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-leave-to
{
  transform: translateX(-100%);
  opacity: 0;
}




.slide-fade-back-enter-active {
  transition: all .25s ease;
}
.slide-fade-back-leave-active {
  transition: all .25s cubic-bezier(0.470, 0.000, 0.745, 0.715);
}
.slide-fade-back-enter
{
  transform: translateX(-100%);
  opacity: 0;
}

.slide-fade-back-leave-to
{
  transform: translateX(100%);
  opacity: 0;
}


/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(167, 63, 212, 0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}
</style>
